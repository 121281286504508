<template>
  <!--
    The header of the page. Used to display the logo, the breadcrumbs, the menu-toggler (collapses main-menu) and the languageSelect.
  -->
  <header
    id="m_header"
    class="m-grid__item m-header"
    m-minimize-offset="200"
    m-minimize-mobile-offset="200"
  >
    <div class="m-container m-container--fluid m-container--full-height">
      <div class="m-stack m-stack--ver m-stack--desktop">
        <div class="m-stack__item m-brand m-brand--skin-light">
          <div class="m-stack m-stack--ver m-stack--general">
            <Logo />
            <MenuToggler />
          </div>
        </div>
        <div
          id="m_header_nav"
          class="m-stack__item m-stack__item--fluid m-header-head"
        >
          <BreadCrumbs />
          <div
            id="m_header_topbar"
            class="m-topbar m-stack m-stack--ver m-stack--general m-stack--fluid"
          >
            <div class="m-stack__item m-topbar__nav-wrapper">
              <a
                v-if="!mobileMixin_isMobile"
                id="freshstatus-badge-root"
                href="https://alturos.freshstatus.io"
                target="_blank"
                data-banner-style="compact"
              > <img src="https://public-api.freshstatus.io/v1/public/badge.svg/?badge=dce0f20b-5e8b-4b46-a9d9-5f2401878108"> </a>
              <ul class="m-topbar__nav m-nav m-nav--inline">
                <li class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light">
                  <span class="h-100 d-table">
                    <span class="m-topbar__welcome d-table-cell verticalAlignMiddle">
                      <LanguageSelect />
                    </span>
                  </span>
                </li>
                <Profile />
                <Notification />
                <Help />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mobileMixin } from '@/mixins/mobileMixin';

export default {
  name: "Head",
  components: {
    BreadCrumbs: () => import('@/components/Head/Breadcrumb.vue'),
    Profile: () => import('@/components/Head/Profile.vue'),
    MenuToggler: () => import('@/components/Head/MenuToggler.vue'),
    Help: () => import('@/components/Head/Help.vue'),
    Logo: () => import('@/components/Head/Logo.vue'),
    Notification: () => import('@/components/Head/Notification.vue'),
    LanguageSelect: () => import('@/components/Base/LanguageSelect.vue')
  },
  mixins: [
    mobileMixin
  ],
  data () {
    return {
      profileOpen: false
    }
  },
  mounted () {
      let fdScript = document.createElement('script')
      fdScript.setAttribute('src', 'https://cdn.freshstatus.io/widget/index.js')
      fdScript.setAttribute('type', 'module')
      document.head.appendChild(fdScript)
    },
}
</script>

<style scoped>

#freshstatus-badge-root {
    border-radius: 5px;
    padding: 3px;
}

#m_header {
  z-index: 9999;
}
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav > .m-menu__item.m-menu__item--open > .m-menu__heading,
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav > .m-menu__item.m-menu__item--open > .m-menu__link {
    background: none;
}
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav > .m-menu__item.m-menu__item--active > .m-menu__heading,
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav > .m-menu__item.m-menu__item--active > .m-menu__link {
    background: #f8f8fb !important;
}
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item.m-menu__item--active > .m-menu__heading,
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item.m-menu__item--active > .m-menu__link {
    background: none;
}
.m-menu__item.router-link-exact-active.m-menu__item--active > a {
    background: #f8f8fb !important;
}
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav > .m-menu__item.m-menu__item--open > .m-menu__link:hover {
    background: #f8f8fb !important;
}

.m-topbar .m-topbar__nav.m-nav {
    margin: 0 30px 0 15px;
}
</style>
